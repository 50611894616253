import './App.css';
import Header from './Components/Header'
import Home from './Pages/Home';
import Products from './Pages/Products';
import ProductDetails from './Pages/ProductDetails';
import Info from './Pages/Info';
import Panier from './Components/panier';
import Footer from './Components/Footer';
import SearchResults from './Components/ProductSearch';
import { BrowserRouter as Router, Routes,Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from "@mui/material";

function App() {
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1080,
        lg: 1280,
      },
    },
    palette: {
      primary: {
         
              main: '#859e96',
              dark: '#af7c80',
              contrastText: '#fff',
        
      },
      secondary: {
         
        main: '#af7c80',
        dark: '#859e96',
        contrastText: '#fff',
  
  },
    },
  });
  return (
    <ThemeProvider theme={customTheme}>
    <div className="App">
     <Router>
      <Header />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/collection/" element={<Products/>}/>
      <Route path="/collection/:name" element={<Products/>}/>
      <Route path="/produit/:name/:name" element={<ProductDetails/>}/>
      <Route path="/contact" element={<Info />} />
      <Route path="/panier" element={<Panier />} />
      <Route path="/search" element={<SearchResults/>}/>
      </Routes>
      <Footer/>
    </Router>
    </div>
    </ThemeProvider>
  );
}

export default App;
