/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import "./style.css";
import * as React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import List from "../list";
import TextField from "@mui/material/TextField";
import ContactsIcon from '@mui/icons-material/Contacts';
import logo from "../../Images/logo.png";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import HomeIcon from '@mui/icons-material/Home';
import { Button, ThemeProvider, createTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Badge from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ReactGA from "react-ga";
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -5,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1080,
      lg: 1280,
    },
  },
  palette: {
    primary: {
       
            main: '#859e96',
            dark: '#af7c80',
            contrastText: '#fff',
      
    },
    secondary: {
       
      main: '#af7c80',
      dark: '#859e96',
      contrastText: '#fff',

},
  },
});

function Header() {
  
  const [isOpen2, setIsOpen2] = useState(false);
  const menuRef = useRef(null); // Référence au menu

  const toggleMenu2 = () => {
    setIsOpen2(!isOpen2);
  };
  const handleMouseLeave = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen2(false);
    }
  };
  
  // Fonction pour fermer le menu si le clic est en dehors du menu
  useEffect(() => {
    // Ajouter un écouteur d'événements pour les mouvements de souris
    document.addEventListener("mousemove", handleMouseLeave);
  
    // Nettoyer l'écouteur d'événements à la désactivation du composant
    return () => {
      document.removeEventListener("mousemove", handleMouseLeave);
    };
  }, []);
  
  const [isOpen, setIsOpen] = useState(false);
  let navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const track_id = "G-6PYC1EK820";
    ReactGA.initialize(track_id);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const cartItems = JSON.parse(localStorage.getItem("cart"));

  const cartContain = cartItems ? cartItems.length : 0;

  const [inputText, setInputText] = useState("");
  const [showList, setShowList] = useState(false);
  const textFieldRef = useRef(null);

  const inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
    setShowList(true);
  };

  const handleSearchClick = () => {
    performSearch();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      performSearch();
    }
  };

  const performSearch = () => {
    if (inputText.trim().length > 0) {
      setShowList(false);
      navigate(`/search?query=${encodeURIComponent(inputText.trim())}`);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        textFieldRef.current &&
        !textFieldRef.current.contains(event.target)
      ) {
        setShowList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const location = useLocation();

  useEffect(() => {
    const pageTitle = location.pathname.split("/").pop();
    if (pageTitle === "") {
      // eslint-disable-next-line no-useless-concat
      document.title = "Saveurs de Fatma";
    } else {
      document.title = `Saveurs de Fatma - ${decodeURIComponent(pageTitle).replace(/%20/g, " ")}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ThemeProvider theme={customTheme}>
      <div className="header" id="header">
    

        <div className="navbar">
          <div className="logoDiv">
            <Link to="/">
              <img src={logo} alt="logo" className="logo" />
            </Link>

            <IconButton
              sx={{
                display: {
                  lg: "none",
                  md: "none",
                  sm: "none",
                  xs: "inline-block",
                  zIndex: 990999999999999,
                },
              }}
              onClick={toggleMenu}
            >
              {!isOpen ? (
                <MenuIcon
                  id="openIcon"
                  fontSize="small"
                  color="secondary"
                  sx={{
                    top: "0px",
                    right: "0px",
                  }}
                />
              ) : (
                <CloseIcon
                  id="closeIcon"
                  color="primary"
                  fontSize="medium"
                  sx={{
                    top: "25px",
                    right: "20px",

                    position: "fixed",
                  }}
                />
              )}
            </IconButton>
            <div id="navMenu" className={isOpen ? "linkMenu show" : "linkMenu"}>
              <ol>
                <div className="socialMediaIcon">
                  <h3>Suivez-nous :</h3>
                  <a
                    href="https://www.instagram.com/saveurs_de_fatma"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon sx={{ color: "#283943" }} fontSize="small" />
                  </a>
                
                  <a
                    href="https://www.facebook.com/profile.php?id=100028842476605"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookIcon sx={{ color: "#283943" }} fontSize="small" />
                  </a>
                </div>
                <li>
                  <div className="iconContainer">
                    <a onClick={toggleMenu} className={`linkItems `} href="/">
                    <Button color="secondary"  endIcon={ <HomeIcon/>}> Acceuil </Button>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="iconContainer">
                    <a onClick={toggleMenu2} className={`linkItems `} href="#" >
                    {isOpen2?(
                    <Button color="secondary"  endIcon={ < KeyboardArrowUpIcon/>}> Nos Gammes </Button>
                    ):(
                    <Button color="secondary"  endIcon={ < KeyboardArrowDownIcon/>}> Nos Gammes </Button>)}
                    </a>
                  </div>
                </li>
                {isOpen2 && (
                <li>
                  {linkMenu.map((icon, index) => (
                    <div key={index} className="iconContainer">
                      <a
                        href={icon.lien}
                        target="_blank"
                        rel="noreferrer"
                        className="linkItems"
                      >
                        <span>{icon.name}</span>
                      </a>
                    </div>
                  ))}
                </li>)}
                <li>
                  <div className="iconContainer">
                    <a onClick={toggleMenu} className={`linkItems `} href="/collection/MACARONS">
                    <Button color="secondary"  > MACARONS </Button>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="iconContainer">
                    <a onClick={toggleMenu} className={`linkItems `} href="/collection/CHOCOLAT">
                    <Button color="secondary"  > CHOCOLAT </Button>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="iconContainer">
                    <a onClick={toggleMenu} className={`linkItems `} href="/collection/SALÉ">
                    <Button color="secondary"  > SALÉ </Button>
                    </a>
                  </div>
                </li>
                <li>
                  <div className="iconContainer">
                    <a onClick={toggleMenu} className={`linkItems `} href="/contact">
                    <Button color="secondary" endIcon={<ContactsIcon/>} > Contact </Button>
                    </a>
                  </div>
                </li>
              </ol>
            </div>
          </div>
          <div className="userDiv">
            <div className="searchListDiv" ref={textFieldRef}>
              <TextField
                type="search"
                variant="standard"
                onChange={inputHandler}
                onKeyPress={handleKeyPress}
                label="Rechercher ..."
                size="small"
                sx={{ width: "100%",fontFamily:'Magie' }}
                color="primary"
                InputProps={{
                  endAdornment: (
                    <SearchIcon
                      color="primary"
                      onClick={handleSearchClick}
                      sx={{ cursor: "pointer" }}

                    />
                  ),
                }}
              />
              <div className="searchList">
                {showList && <List input={inputText} />}
              </div>
            </div>

            <Link to="/panier">
              <IconButton aria-label="cart" color="secondary" title="Panier">
                <StyledBadge badgeContent={cartContain} color="secondary">
                  <ShoppingCartIcon />
                </StyledBadge>
              </IconButton>
            </Link>
          </div>
        </div>
      </div>
      <div className="navbarBottom">
        <div className="navbarIcon">
        <div  className="iconContainer">
        <div className="custom-menu" ref={menuRef} onMouseLeave={() => setIsOpen2(false)}>
      <Button className="header-Btn" variant="contained" endIcon={<KeyboardArrowDownIcon/>}  onMouseEnter={() => setIsOpen2(true)} >
       Nos Gammes
      </Button>
      {isOpen2 && (
        <div className="menu-content">
          <ul>
          {linkMenu.map((icon, index) => (
                      <a
                        href={icon.lien}
                        target="_blank"
                        rel="noreferrer"
                        className="linkItems"
                      >
<li>{icon.name}</li>
                   <hr style={{width:'100%'}}></hr>
                      </a>
                  ))}
            
         
          </ul>
        </div>
      )}
    </div>
        <a href="/collection/MACARONS" target="_blank" rel="noreferrer">
        <Button className="header-Btn" variant="contained">MACARONS</Button>
              </a>
                <a href="/collection/CHOCOLAT" target="_blank" rel="noreferrer">
                

                <Button className="header-Btn" variant="contained">CHOCOLAT</Button>
              </a>
              <a href="/collection/SALÉ" target="_blank" rel="noreferrer">
                

              <Button className="header-Btn" variant="contained">SALÉ</Button>
              </a>
              <a href="/contact" >
        <Button className="header-Btn" variant="contained">Contact</Button>
              </a>
              </div>
        </div>
       
      </div>
    </ThemeProvider>
  );
}

export default Header;

const linkMenu = [
  {
    name: "NAISSANCE", lien: "/collection/NAISSANCE", },
  { name: "CIRCONCISION", lien: "/collection/CIRCONCISION" },
  { name: "BAKLEWA", lien: "/collection/BAKLEWA" },
  { name: "BJEWIYA", lien: "/collection/BJEWIYA" },
  { name: "HLOU", lien: "/collection/HLOU" },
  { name: "BOULES", lien: "/collection/BOULES" },
  { name: "KAAK", lien: "/collection/KAAK" },
  { name: "MLABBES", lien: "/collection/MLABBES" },
  { name: "MIGNARDISE", lien: "/collection/MIGNARDISE" },
  { name: "HLOU ARBI", lien: "/collection/HLOU ARBI" },
  { name: "HLOU thé", lien: "/collection/HLOU thé" },
  { name: "sablé", lien: "/collection/sablé" },
];