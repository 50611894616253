/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import ScrollToTop from "../../Components/scrollpageUp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import MailIcon from "@mui/icons-material/Mail";
import "./style.css";
import contact from "../../Images/contact.jpg";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import { useInView } from "framer-motion";
import { useRef } from "react";
export default function Info() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#000",
        dark: "#222",
        contrastText: "#FFCD00",
      },
    },
  });
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const isInView = useInView(ref1, { once: true });
  const isInView2 = useInView(ref2, { once: true });
  return (
    <ThemeProvider theme={theme}>
      <div className="info">
        <div className="infoHeader" ref={ref1} style={{ 
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
          }}>
          <div >
            <h1>Bienvenue chez Saveurs de Fatma </h1>
            <p className="infoDescreption">
              Saveurs de Fatma est l'incarnation d'une gastronomie raffinée et
              l'essence d'un héritage transmis avec passion. Fondée en 2007 à
              Sfax, par Madame Fatma Chaabouni, cette pâtisserie fine est le
              témoin d'une tradition riche et fidèle, offrant une expérience
              culinaire empreinte de subtilité et d'intemporalité. Saveurs de
              Fatma perpétue avec élégance un savoir-faire authentique,
              reflétant la richesse de son histoire et la finesse de ses
              créations
            </p>
            <ScrollToTop />
          </div>
          <img src={contact} alt="info Pic" className="infoPic" />
        </div>
        <div>
          <div className="infoDetails" ref={ref2} style={{ 
            transform: isInView2 ? "none" : "translateX(200px)",
            opacity: isInView2 ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.6s"
          }}>
            <div>
              <h4> contact </h4>
              <a href="tel:53656288" className="footer-link">
                {" "}
                <PhoneAndroidIcon fontSize="small" />
                53 656 288
              </a>
              <br />
              <a
                href="mailto:saveursdefatmachaabouni@gmail.com"
                className="contact-link"
              >
                <MailIcon fontSize="small" /> saveursdefatmachaabouni@gmail.com
              </a>
              <br />
              <a href="#" className="contact-link">
                <LocationOnIcon fontSize="small" />
                Rue Patrice Lumumba , Beb Bhar . Sfax
              </a>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26226.460080067765!2d10.7661638!3d34.74784575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1301d38e82ede065%3A0x28c601f72aabbd95!2sSAVEURS%20de%20fatma%20mme%20ch%C3%A2abouni!5e0!3m2!1sfr!2stn!4v1725878914846!5m2!1sfr!2stn"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="infoMap"
            ></iframe>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
