import UseFetch from "../../hook/useFetch";
import { List, ListItem } from '@mui/material';
import "./style.css";

function CustomList(props) {
    const { data = [] } = UseFetch("https://saveurs-fatma-backend.vercel.app/api/article");

    // Si data est undefined ou null, initialisez-le en tant que tableau vide
    const produits = Array.isArray(data) ? data : [];
    
    const filteredData = produits.filter((el) => {
        const searchInput = props.input.toLowerCase();
        if (searchInput.length >= 2) {
            return el.articleName.toLowerCase().includes(searchInput) ||
                   el.ref.toLowerCase().includes(searchInput) ||
                   el.famille.toLowerCase().includes(searchInput);
        } else {
            return true;
        }
    });

    return (
        <div className="listOfArticle">
            {props.input.length >= 1 && (
                <List
                    sx={{
                        width: '100%',
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        maxHeight: 400,
                        '& ul': { padding: 0 },
                    }}
                    subheader={<li />}
                >
                    {
                        filteredData.map((item) => (
                            <ListItem
                                key={item.id}
                                button
                                component="a"
                                href={`/produit/${item.famille}/${item.articleName}`}
                            >
                                <h3 className="liArticle">
                                    <img
                                        src={`https://firebasestorage.googleapis.com/v0/b/saveurs-fatma.appspot.com/o/${encodeURIComponent(item.famille)}%2F${item.article.replace(/-/g, ' ')}.jpg?alt=media`}
                                        alt="produit"
                                        className="prodPicList"
                                    />
                                    <div className="listDetails">
                                        <span>{item.articleName} - </span>   
                                        {item.prix===0?( <span style={{ color: '#af7c80' }}> {item.prixPiece} TND / Piéce</span>):
                                       ( <span style={{ color: '#af7c80' }}> {item.prix} TND / KG</span>)}
                                     
                                 
                                  
                                    </div>
                                </h3>
                            </ListItem>
                        ))
                    }
                </List>
            )}
        </div>
    );
}

export default CustomList;
