import React, { useState, useEffect } from "react";
import BestProductCard from "../ProductCardSlide";
import UseFetch from "../../hook/useFetch";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./style.css";

export default function BestBroduct({ categName }) {
  const { data } = UseFetch("https://saveurs-fatma-backend.vercel.app/api/article");
  const produits = Array.from(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(8);

  useEffect(() => {
    const updateItemsPerPage = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1200) {
        setItemsPerPage(5);
      } else if (windowWidth > 700) {
        setItemsPerPage(4);
      } else {
        setItemsPerPage(2);
      }
    };

    updateItemsPerPage();
    window.addEventListener("resize", updateItemsPerPage);

    return () => {
      window.removeEventListener("resize", updateItemsPerPage);
    };
  }, []);

  const filteredProducts = produits.filter((produit) => {
    if (categName) {
      return produit.famille === decodeURIComponent(categName) ;
    } else {
      return true;
    }
  });

  // Mélanger les produits de manière aléatoire
  const shuffledProducts = shuffleArray(filteredProducts);

  const indexOfLastCard = currentPage * itemsPerPage;
  const indexOfFirstCard = indexOfLastCard - itemsPerPage;
  const currentCards = shuffledProducts.slice(
    indexOfFirstCard,
    indexOfLastCard
  );
  const totalPages = Math.ceil(shuffledProducts.length / itemsPerPage);

  // eslint-disable-next-line no-unused-vars
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-arrow custom-prev" onClick={onClick}>
        <ArrowBackIosIcon />
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="custom-arrow custom-next" onClick={onClick}>
        <ArrowForwardIosIcon />
      </div>
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: itemsPerPage,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  return (
    <div>
      <Slider {...settings}>
        {currentCards.map((produit, index) => (
          <div key={produit.article + index} className="bestProduct">
            <BestProductCard
              key={produit.article + index}
              categorie={produit.categorie}
              article={produit.article}
              subcategorie={produit.subcategorie}
              prix={produit.prix}
              brand={produit.brand}
              famille={produit.famille}
              prixPiece={produit.prixPiece}
            />
          </div>
        ))}
      </Slider>
      {totalPages > 1 && ( 
        <div className="pagination">
          {/* Pagination */}
        </div>
      )}
    </div>
  );
}

// Fonction pour mélanger un tableau
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
