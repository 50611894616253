/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "./style.css";
import React, { useState, useEffect } from "react";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import VerifiedIcon from "@mui/icons-material/VerifiedOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DiscountIcon from "@mui/icons-material/Discount";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import Footer from "../../Components/Footer";
import ScrollToTop from "../../Components/scrollup";
import UseFetch from "../../hook/useFetch";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import BestBroduct from "../../Components/ProductRandom";
import ProductBrand from "../../Components/ProductBrand";
import { Dialog, Box } from "@mui/material";
import Rating from "@mui/material/Rating";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Axios } from "axios";
export default function ProductDetails() {
  const [quantity, setQuantity] = useState(500);

  function plus() {
    setQuantity((prevQuantity) => prevQuantity + 100);
  }

  function moin() {
    setQuantity((prevQuantity) => {
      const MIN_THRESHOLD = 500;
      return prevQuantity > MIN_THRESHOLD
        ? Math.max(prevQuantity - 100, MIN_THRESHOLD)
        : prevQuantity;
    });
  }

  function updateProductQuantity(produit, newQuantity) {
    // Retrieve the cart from local storage
    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

    // Find the index of the product in the cart
    const productIndex = existingCart.findIndex(
      (item) => item.name === produit.articleName
    );

    if (productIndex !== -1) {
      // Update the quantity of the existing product
      existingCart[productIndex].quantite = newQuantity;
    } else {
      // Optionally handle the case where the product is not in the cart
      existingCart.push({
        family: produit.famille,
        name: produit.articleName,
        img: `${path1} ${path2}`,
        categorie: produit.categorie,
        souscategorie: produit.subcategorie,
        price: produit.prixFlash
          ? produit.prixFlash.toFixed(3)
          : produit.prix.toFixed(3),
        brand: produit.brand,
        reference: produit.ref,
        quantite: newQuantity,
      });
    }

    // Save the updated cart back to local storage
    localStorage.setItem("cart", JSON.stringify(existingCart));
  }

  const { data, loading } = UseFetch(
    "https://saveurs-fatma-backend.vercel.app/api/article"
  );
  const [userRating, setUserRating] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarer, setOpenSnackbarer] = useState(false);
  const produits = Array.from(data);
  const [openRate, setOpenRate] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);
  const user = localStorage.getItem("log");
  const username = localStorage.getItem("name");
  const [couleurSelectionnee, setCouleurSelectionnee] = useState(null);

  const handleClickCouleur = (color) => {
    // Mettre à jour l'état avec la couleur sélectionnée
    setCouleurSelectionnee(color);
  };
  const handleOpenRate = () => {
    setOpenRate(true);
  };
  const handleCloseRate = () => {
    setOpenRate(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleOpenDialog2 = () => {
    setOpenDialog2(true);
  };
  const handleOpenDialog3 = () => {
    setOpenDialog3(true);
  };
  // Fonction pour fermer la boîte de dialogue
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseDialog2 = () => {
    setOpenDialog2(false);
  };
  const handleCloseDialog3 = () => {
    setOpenDialog3(false);
  };
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg")); // Vérifie si l'écran est de taille large (lg et supérieur)
  const buttonSize = isLargeScreen ? "medium" : "small";
  const [display, setDisplay] = useState(false);
  function displayDescreption() {
    setDisplay(display === false ? true : false);
  }
  let pathName = window.location.pathname;
  let pathe = pathName.replace(/%20/g, " ");
  let path1 = pathe.split("/")[2];
  let path2 = pathe.split("/")[3];
  const [open, setOpen] = React.useState(false);
  const addToCart = () => {
    const productIndex = produits.findIndex((produit) =>
      path2 ? produit.articleName === path2 : produit.articleName === path2
    );

    if (productIndex !== -1) {
      // Récupérer les détails du produit à partir de 'produits'
      const product = produits[productIndex];

      // Récupérer le panier depuis le stockage local ou initialiser un nouveau panier s'il est vide
      const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

      const existingProductIndex = existingCart.findIndex((item) =>
        path2 ? item.articleName === path2 : item.articleName === path2
      );

      if (existingProductIndex !== -1) {
        existingCart[existingProductIndex].quantite += 1;
      } else {
        let price = product.prixFlash ? product.prixFlash : product.prix;

        existingCart.push({
          family: product.famille,
          name: product.articleName,
          img: `${path1} ${path2}`,
          categorie: product.categorie,
          souscategorie: product.subcategorie,
          price: price.toFixed(3),
          brand: product.brand,
          reference: product.ref,
          quantite: quantity,
        });
      }

      localStorage.setItem("cart", JSON.stringify(existingCart));

      handleClick();

      window.location.reload();
    }
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const currentProduct = produits.find((produit) =>
    path2 ? produit.articleName === path2 : produit.articleName === path2
  );
  const sendRatingToAPI = () => {
    fetch(
      `https://saveurs-fatma-backend.vercel.app/api/article/${currentProduct._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          rate: userRating,
          userRate: { username: username, rate: userRating },
        }),
      }
    )
      .then((response) => {
        if (response.ok) {
          // Affichez un message de succès

          window.location.reload();
          setOpenSnackbar(true);
        } else {
          setOpenSnackbarer(true);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de l'envoi du rating :", error);
        // Gérez les erreurs si nécessaire
      });
  };

  useEffect(() => {
    if (userRating > 0) {
      sendRatingToAPI();
    }
  }, [userRating]);
  function sumArrayValues(arr) {
    if (Array.isArray(arr)) {
      return (
        arr.reduce((total, currentValue) => total + currentValue, 0) /
        arr.length
      );
    }
    return 0;
  }

  function lengthUser(arr) {
    if (Array.isArray(arr)) {
      return arr.length;
    }
    return 0;
  }
  const totalRate = currentProduct
    ? sumArrayValues(currentProduct.rate).toFixed(1)
    : 0;
  const totalUser = currentProduct ? lengthUser(currentProduct.userRate) : 0;
  function calculateRatingCounts(ratings) {
    if (!ratings || !Array.isArray(ratings)) {
      return [0, 0, 0, 0, 0];
    }

    const ratingCounts = [0, 0, 0, 0, 0];

    ratings.forEach((rating) => {
      ratingCounts[rating - 1]++;
    });

    return ratingCounts;
  }

  const ratingCounts = currentProduct
    ? calculateRatingCounts(currentProduct.rate)
    : [0, 0, 0, 0, 0];
  return (
    <div className="products details" id="produits">
      <div className="productDetails">
        <span className="navbarCollection details">
          collections
          {decodeURIComponent(path1) && <a href={`/collection/${path1}`}>{` / ${decodeURIComponent(path1)}`} </a>}
          {decodeURIComponent(path2) && (
            <a href={`/produit/${path1}/${path2}`}>
              {` / ${decodeURIComponent(path2)}`}{" "}
            </a>
          )}
        </span>
        <ScrollToTop />
        {loading ? (
          <div className="loader details"></div>
        ) : (
          <div className="productContainDetail">
        
            <div>
              {produits.map((produit, index) =>
                produit.articleName === decodeURIComponent(path2) ? (
                  <div className="productcardDetails" key={index}>
                  
                        <img
                          className="cardDetails"
                          src={`https://firebasestorage.googleapis.com/v0/b/saveurs-fatma.appspot.com/o/${decodeURIComponent(
                            path1
                          )}%2F${decodeURIComponent(
                            path1
                          )}%20${decodeURIComponent(path2)}.jpg?alt=media`}
                          title={path2}
                          alt={path2}
                          onClick={() => handleOpenDialog()}
                        />
                    <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      sx={{ zIndex: 999999999999 }}
                    >
                      <img
                         src={`https://firebasestorage.googleapis.com/v0/b/saveurs-fatma.appspot.com/o/${decodeURIComponent(
                          path1
                        )}%2F${decodeURIComponent(
                          path1
                        )}%20${decodeURIComponent(path2)}.jpg?alt=media`}
                        title={path2}
                        alt={path2}
                      
                        style={{
                          maxWidth: "100%",
                          maxHeight: "80vh",
                          objectFit: "contain",
                          zIndex: 9999999,
                        }}
                      />
                    </Dialog>

                    <div className="morecardDetailP">
                      <h2>
                        {produit.articleName} - {produit.brand} <span style={{color:"#859e96"}}>ref : {produit.ref}</span>
                      </h2>
                   {produit.prix===0?(<h4>
                            {produit.prixPiece} TND / Piéce <br />
                          </h4>):(
                          <h4>
                            {produit.prix.toFixed(0)} TND / kG <br />
                          </h4>)}
                      
                        {produit.stock===0||produit.famille==="CHOCOLAT"||produit.famille==="SALÉ"||produit.famille==="MACARONS"?(<></>):(
                          <>
                      <div className="quantity">
                        <div className="plusmoin" onClick={moin}>
                          <RemoveCircleIcon
                            color="secondary"
                            fontSize="medium"
                            sx={{ cursor: "pointer" }}
                          />
                        </div>
                        <div className="numberArticle">{quantity} g</div>
                        <div className="plusmoin" id="plusMoin" onClick={plus}>
                          <AddCircleIcon
                            color="secondary"
                            fontSize="medium"
                            sx={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>

                      <Button
                        sx={{
                          marginTop: 5,
                          paddingX:5,
                          fontFamily: "Exo",
                          fontSize: {
                            lg: "14px",
                            md: "12px",
                            sm: "12px",
                            xs: "10px",
                          },
                        }}
                        variant="contained"
                        color="secondary"
                        size={buttonSize}
                        startIcon={<ShoppingCartIcon />}
                        titleAccess={`Ajouter ${produit.articleName} au panier`}
                        onClick={addToCart}
                      >
                        ajouter au panier
                      </Button>
                      </>
                        )}
                      <div className="profitDetail">
                        {/*    <span className="profitDetailspan">Profitez!</span>
                          <Box sx={{ display: "flex", marginTop: "10px" }}>
                       <DiscountIcon fontSize="small" color="warning" />
                          <span
                            className="profitDetailspan"
                            style={{ marginLeft: "5px" }}
                          >
                            {" "}
                            Nouveaux clients? : profitez de 5% de remise avec le
                            code{" "}
                            <span style={{ color: "#F77171", fontWeight: 800 }}>
                              HELLO-5
                            </span>{" "}
                          </span>
                        </Box>*/}
                        <Box sx={{ display: "flex", marginTop: "10px" }}>
                          <PermContactCalendarIcon
                            fontSize="small"
                            color="secondary"
                          />
                          <span
                            className="profitDetailspan"
                            style={{ marginLeft: "5px" }}
                          >
                            {" "}
                            Pour plus d'informations! : appelez{" "}
                            <a
                              href="tel:53656288"
                              className="profitDetailspan info"
                            >
                              53 656 288{" "}
                            </a>{" "}
                          </span>
                        </Box>
                      </div>
                      <Snackbar
                        open={open}
                        autoHideDuration={5000}
                        onClose={handleClose}
                      >
                        <Alert
                          onClose={handleClose}
                          severity="success"
                          variant="filled"
                          sx={{ width: "100%" }}
                        >
                          {produit.articleName} a été ajouté au panier !
                        </Alert>
                      </Snackbar>
                    </div>
                    {/*--------------------------------------------------------*/}
                  </div>
                ) :  (
                  
                  <></>
                )
              )}
              {/*--------------------------------------------------------*/}
            </div>
          </div>
        )}
      </div>
      <div className="bestProductDiv">
        <h2>Notre sélection pour vous </h2>
        <BestBroduct categName={path1}  />
       
      </div>
    </div>
  );
}
