import React, { useState, useEffect } from "react";
import ProductCard from "../ProductCard";
import { Button, Pagination } from "@mui/material";
import "./style.css";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import UseFetch from "../../hook/useFetch";
import { useLocation } from "react-router";

export default function AllProducts({ souscategName, categName, familyName }) {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page')) || 1;
    setCurrentPage(page);
  }, [location.search]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(18);
  const [sortBy, setSortBy] = useState("nameAsc");
  const { data, loading } = UseFetch("https://saveurs-fatma-backend.vercel.app/api/article");
  const produits = Array.from(data);

  const handleSortToggle = () => {
    setSortBy(prevSortBy => (prevSortBy === 'priceAsc' ? 'priceDesc' : 'priceAsc'));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', newPage.toString());
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({}, '', newUrl);
  };

  const filteredProducts = familyName === "collection" ? produits : produits.filter((produit) => {
    const matchesCategorie = !categName || categName === produit.categorie;
    const matchesFamille = !familyName || familyName === produit.famille;
    const matchesSousCategorie = !souscategName || souscategName === produit.subcategorie;
    return matchesCategorie && matchesFamille && matchesSousCategorie;
  });

  // Sorting logic
  let sortedProducts = [...filteredProducts];
  if (sortBy === "priceAsc") {
    sortedProducts.sort((a, b) => (a.prix > b.prix ? 1 : -1));
  } else if (sortBy === "priceDesc") {
    sortedProducts.sort((a, b) => (a.prix < b.prix ? 1 : -1));
  } else if (sortBy === "nameAsc") {
    sortedProducts.sort((a, b) => {
      const nameA = a.articleName || ""; // Fallback to empty string
      const nameB = b.articleName || ""; // Fallback to empty string
      return nameA.localeCompare(nameB);
    });
  } else if (sortBy === "nameDesc") {
    sortedProducts.sort((a, b) => {
      const nameA = a.articleName || ""; // Fallback to empty string
      const nameB = b.articleName || ""; // Fallback to empty string
      return nameB.localeCompare(nameA);
    });
  }

  const indexOfLastCard = currentPage * itemsPerPage;
  const indexOfFirstCard = indexOfLastCard - itemsPerPage;
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const currentCards = sortedProducts.slice(indexOfFirstCard, indexOfLastCard);

  return loading ? (
    <div className="loader"></div>
  ) : (
    <div>
      <div className="triDiv">
        <Button
          sx={{ fontFamily: 'Nunito', letterSpacing: '1px', fontWeight: '600', fontSize: { lg: '12px', md: '12px', sm: '12px', xs: '10px' } }}
          size="small"
          variant="outlined"
          color="secondary"
          onClick={handleSortToggle}
          endIcon={<SwapVertIcon />}
        >
          Prix
        </Button>
        <Button
          sx={{ fontFamily: 'Nunito',marginLeft:'2px', letterSpacing: '1px', fontWeight: '600', fontSize: { lg: '12px', md: '12px', sm: '12px', xs: '10px' } }}
          size="small"
          variant="outlined"
          color="secondary"
          onClick={() => setSortBy(prevSortBy => prevSortBy === 'nameAsc' ? 'nameDesc' : 'nameAsc')}
          endIcon={<SwapVertIcon />}
        >
          A-Z
        </Button>
      </div>

      <div className="allProduct">
        {currentCards.map((produit, index) => (
          <ProductCard
            key={produit.article}
            index={index}
            article={produit.article}
            prix={produit.prix}
            prixPiece={produit.prixPiece}
            nbrPiece={produit.nbrPiece}
            famille={produit.famille}
            reference={produit.ref}
            stock={produit.stock}
          />
        ))}
      </div>

      {totalPages > 1 && (
        <div className="pagination">
          <Pagination
            count={totalPages}
            page={currentPage}
            shape="rounded"
            variant="outlined"
            onChange={(event, newPage) => handlePageChange(newPage)}
            color="secondary"
            size="small"
          />
        </div>
      )}
    </div>
  );
}
