import "./style.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import * as React from "react";
import {  createTheme, ThemeProvider } from "@mui/material";

export default function NewProductHome({
  famille,
  article,
  nbrPiece,
  prixPiece,
  prix,
  brand,
  stock
}) {
  const articleName = article.split('-')[1];
  const articlImg = article.replace(/-/g, ' ');
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 900,
        lg: 1200,
      },
    },
  });
 
    
  return (
    <ThemeProvider theme={customTheme}>
      <div className="cardNew">
        <Card
          className="cardNew"
          sx={{
            height: { lg: "250px", md: "250px", sm: "240px", xs: "180px" },
            width: { lg: "13vw", md: "20vw", sm: "20vw", xs: "23vw" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2
          }}
          elevation={4}
        >
          
            
          <a className="cardMediaLink" href={`/produit/${famille}/${articleName}`}>
           
              <>
              
              <img
                className="cardMediaNew"
                src={`https://firebasestorage.googleapis.com/v0/b/saveurs-fatma.appspot.com/o/${decodeURIComponent(famille)}%2F${decodeURIComponent(articlImg)}.jpg?alt=media`}
                title={articleName}
                alt={articleName}
              />
              </>
           
          </a>
       
          <CardContent
            sx={{
              borderTop: 'solid rgba(0, 0, 0, 0.190) 1px',
              width: "95%",
              height: "auto",
              position: 'absolute',
              bottom: 0,
              padding: 1,
              marginTop: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            
            <div style={{ display: 'flex', flexDirection: 'column',width:'100vw'}}>
              <span className="articleDetaileNew" style={{ fontWeight: 'bold' }}>
                {articleName}
              </span>
              {nbrPiece===0?(<></>):(
              <span className="articleDetaileNew" >
              
              {nbrPiece}  Pieces / Kg 
              </span>
              )}
              {prix===0?( <span className="articleDetaileNew"style={{color:"#af7c80"}} >{prixPiece} TND / Piéce </span> ):(
              <span className="articleDetaileNew" style={{color:"#af7c80"}}  >
                {prix} TND / 1 kg
              </span>
              )}
           
            </div>
          </CardContent>
        </Card>
      </div>
    </ThemeProvider>
  );
}
